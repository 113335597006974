<template>
    <div class="page-navbar">
        <div class="container">
            <nav class="navbar navbar-expand-md">
                <button class="navbar-toggler" v-if="isAuthenticated" type="button" @click="display.userMenu=!display.userMenu">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Brand -->
                <router-link class="navbar-brand" :to="{name:'home'}"/>
                <!-- Mobile: start -->
                <ul class="navbar-nav ml-md-auto d-md-none flex-row">
                    <!-- Cart icon is always displayed -->
                    <li class="nav-item">
                        <router-link class="nav-link navbar-link-cart" :to="{name:'order.submit'}">
                            <span class="icon-basket"></span>
                            <span v-if="0 < cartItems.length"
                                  class="badge badge-pill badge-danger">{{cartItems.length}}</span>
                        </router-link>
                    </li>
                </ul>
                <!-- Mobile: end -->

                <!-- Table,Desktop: start -->


                <!-- Table,Desktop: end -->


                <div class="collapse navbar-collapse" v-if="isAuthenticated" :class="{show:display.userMenu}">
                    <ul class="navbar-nav navbar-nav-bordered ml-md-auto">
                        <!-- Support Widget -->
                        <li v-if="widget_displayWidgetButton" class="nav-item">
                            <a href="#" class="nav-link" role="button" :data-widget-trigger="widget_optionsJson">
                                <span class="nav-link-text">{{t('support')}}</span>
                            </a>
                        </li>
                        <!-- Cart -->
                        <li class="nav-item d-none d-md-block">
                            <router-link class="nav-link navbar-link-cart" :to="{name:'order.submit'}">
                                <span class="icon-basket"></span>
                                <span class="badge badge-pill badge-danger"
                                      v-if="0<cartItems.length">{{cartItems.length}}</span>
                            </router-link>
                        </li>
                        <!-- User -->
                        <li class="nav-item d-none d-md-block dropdown">
                            <!-- Tablet, Desktop -->
                            <a href="#" class="nav-link" role="button" v-dropdown.desktopUserMenu>
                                <span class="icon-user"></span>
                                <span class="nav-link-text user-name">{{userName}}</span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" ref="desktopUserMenu">
                                <router-link v-if="enable.profile.edit" class="dropdown-item" :to="{name:'quiz', query: {reset: true}}">
                                    {{$t('navigation.user.profile.edit')}}
                                </router-link>
                                <router-link v-if="enable.approvals.delegate" class="dropdown-item" :to="{name:'approvals.delegate'}">
                                    {{$t('navigation.approvals.delegates')}}
                                </router-link>
                                <router-link class="dropdown-item" :to="{name:'auth.logout'}">
                                    {{$t('navigation.logout')}}
                                </router-link>
                            </div>
                        </li>
                        <!-- Mobile -->
                        <li class="nav-item d-md-none">
                            <router-link v-if="enable.profile.edit" class="nav-link" :to="{name:'quiz', query: {reset: true}}">
                                {{$t('navigation.user.profile.edit')}}
                            </router-link>
                            <router-link v-if="enable.approvals.delegate" class="nav-link" :to="{name:'approvals.delegate'}">
                                {{$t('navigation.approvals.delegates')}}
                            </router-link>
                            <router-link class="nav-link" :to="{name:'auth.logout'}">{{$t('navigation.logout')}}</router-link>
                        </li>
                        <!-- Language -->
                        <li v-if="1 < languages.length" class="nav-item d-md-none">
                            <!-- Mobile -->
                            <a href="#" class="nav-link" role="button" v-dropdown.languageDropdownMenu>
                                <span class="nav-link-text language-name">{{language.name}}</span>
                                <span class="icon-down-dir"></span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-collapsed" ref="languageDropdownMenu">
                                <a href="#" role="button" class="dropdown-item" v-for="lang in languages"
                                   :key="lang.value"
                                   :class="{active:lang.value==language.value}"
                                   @click.prevent="setLanguage(lang)">
                                    <span class="nav-link-text">{{lang.name}}</span>
                                </a>
                            </div>
                        </li>
                        <li v-if="1 < languages.length" class="nav-item d-none d-md-block dropdown">
                            <!-- Tablet, Desktop -->
                            <a href="#" class="nav-link" role="button" v-dropdown.languageDropdownMenuDesktop>
                                <span class="language-name nav-link-text">{{language.name}}</span>
                                <span class="icon-down-dir"></span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" ref="languageDropdownMenuDesktop">
                                <a href="#" role="button" class="dropdown-item" v-for="lang in languages"
                                   :key="lang.value"
                                   :class="{active:lang.value==language.value}"
                                   @click.prevent="setLanguage(lang)">
                                    {{lang.name}}
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <ul v-else-if="1 < languages.length" class="navbar-nav ml-auto">
                    <li class="nav-item dropdown">
                        <a href="#" class="nav-link" role="button" v-dropdown.languageDropdownMenu>
                            <span class="nav-link-text language-name">{{language.name}}</span>
                            <span class="icon-down-dir"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right position-absolute" ref="languageDropdownMenu">
                            <a href="#" role="button" class="dropdown-item" v-for="lang in languages"
                               :key="lang.value"
                               :class="{active:lang.value==language.value}"
                               @click.prevent="setLanguage(lang)">
                                {{lang.name}}
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex"

    import mixin_widget from "@mixins_widget";
    import mixin_view from "@/mixins/view";

    import {PERMISSIONS, USER_MENU_DISPLAY_EDIT_PROFILE} from "@/constants";

    export default {
        name: "PageNavbar",
        mixins: [mixin_widget, mixin_view],
        data() {

            return {
                t_path: "navbar",

                display: {
                    userMenu: false
                },

                enable: {
                    profile: {
                        edit: USER_MENU_DISPLAY_EDIT_PROFILE
                    },
                    approvals: {
                        delegate: false
                    }
                }
            };
        },
        computed: {
            ...mapGetters({
                isAuthenticated: "user/isAuthenticated",
                cartItems: "user/cart/items",
                userName: "user/profile/firstName",
                languages: "i18n/languages",
                language: "i18n/language",
            })
        },
        methods: {
            setLanguage(language) {

                this.$i18n.locale = language.value;

                this.$store.dispatch("i18n/setLanguage", language);
                this.$store.dispatch("user/setLanguage", language.value);

                this.view_setTitle();
            },
            fetch() {

                if (this.$store.getters["user/permissions/hasPermission"](PERMISSIONS.MANAGER)) {

                    this.$platform.get("/api/v2/delegate_config")
                        .then(response => this.enable.approvals.delegate = response.data.results.allow_delegates);
                }
            }
        },
        watch: {
            $route(to) {

                this.display.userMenu = false;
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>