<template>
    <page-message>
        <template #header>{{t('header')}}</template>
    </page-message>
</template>

<script>

    import {encode} from "@/helpers/base64";
    import mixinView from "@/mixins/view";
    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsErrors404",
        components: {
            PageMessage
        },
        mixins: [mixinView],
        data() {

            return {
                t_path: "views.errors.404"
            }
        },
        beforeMount() {

            if ("undefined" === typeof this.$route.name) {

                this.$platform.post(
                    "/api/v2/frontend/log",
                    encode(
                        JSON.stringify({
                            type: "page_not_found",
                            source: "mktportal",
                            details: {
                                path: this.$route.fullPath,
                                referrer: document.referrer
                            }
                        })
                    ),
                    {
                        headers: {
                            "X-Content-Transfer-Encoding": "base64",
                            "Content-Type": "application/json"
                        },
                    }
                );
            }
        }
    }
</script>