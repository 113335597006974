import debounce from "lodash/debounce"
import mixinProducts from "@/mixins/products";
import {mapGetters} from "vuex";
import {ORDER_STATUS} from "@/constants";

export default {
    mixins: [mixinProducts],
    data() {

        return {
            widget_listener: null,
            widgetListenerInterval: null
        }
    },
    computed: {
        ...mapGetters({
            widget_orders: "user/orders/items",
            widget_options: "widget/options"
        }),
        widget_optionsJson() {

            return JSON.stringify({
                anchorMargin: "60px 15px 0 0",
                token: this.$store.getters["user/token"]
            });
        },
        widget_optionsJson_productIssues_productDamaged() {

            return JSON.stringify({
                anchorMargin: "60px 15px 0 0",
                token: this.$store.getters["user/token"],
                selection: [{
                    component: "category",
                    value: "Product Issues"
                }, {
                    component: "issue",
                    value: "Product damaged"
                }]
            });
        },
        widget_optionsJson_orderQuestions_whereOrder() {

            return JSON.stringify({
                anchorMargin: "60px 15px 0 0",
                token: this.$store.getters["user/token"],
                selection: [{
                    component: "category",
                    value: "Order Questions"
                }, {
                    component: "issue",
                    value: "Where is my order?"
                }]
            });
        },
        widget_optionsModels() {

            return {
                widgetOptions: this.widget_optionsJson
            }
        },
        widget_displayWidgetButton() {

            return -1 === ["helpdesk"].indexOf(this.$route.name);
        }
    },
    methods: {
        widget_init() {

            const callback = this.widget_getCallback();
            const token = this.$store.getters["user/token"];
            const addresses = this.widget_options.addresses
                ? this.widget_options.addresses
                : (token ? this.$store.getters["user/address/items"] : []);
            const callbackAddressTFA = addressId => this.$store.dispatch("user/address/confirm", addressId);
            const products = (this.widget_options.orders ? this.widget_options.orders : (token ? this.widget_orders : []))
                .reduce((result, order) => result.concat(order.products), [])
                .filter(product => -1 === [ORDER_STATUS.CANCELED, ORDER_STATUS.RETURNED].indexOf(product.status))
                .filter(product => {

                    if (this.widget_options.orderProduct) {

                        if (product.sku == this.widget_options.orderProduct.sku) {

                            return this.widget_options.orderProduct._id == product._id;
                        }
                    }

                    return true;
                })
                .reduce((result, product) => {

                    const index = result.findIndex(p => p.sku == product.sku);

                    if (-1 === index) {

                        result.push({
                            ...product,
                            name: this.products_translateProductName(product),
                            description: this.products_translateProductDescription(product),
                            note: this.products_translateProductNote(product),
                            quantity: product.quantity ? product.quantity : 1
                        });
                    } else {

                        result[index].quantity += product.quantity ? product.quantity : 1;
                    }

                    return result;
                }, []);

            callback({
                token,
                addresses,
                callbackAddressTFA,
                products,
                email: this.$store.getters["user/profile/email"],
                name: this.$store.getters["user/profile/name"],
                country: this.$store.getters["user/profile/country"],
                language: this.$store.getters["i18n/language"].value,
                target_user: this.widget_options.target_user
            });
        },
        widget_refresh_promise() {

            const callback = this.widget_getCallback();

            if ("function" !== typeof callback) {

                if (this.widget_listener) {

                    let internalListener;

                    internalListener = setInterval(() => {

                        if ("function" === typeof this.widget_getCallback()) {

                            clearInterval(internalListener);
                            this.widget_init();
                        }
                    }, 25);

                } else {

                    // Wait for widget callback is ready for passing data
                    this.widget_listener = setInterval(() => {

                        if ("function" === typeof this.widget_getCallback()) {

                            // Remove existing listener
                            clearInterval(this.widget_listener);
                            this.widget_init();
                        }
                    }, 25);
                }
            }

            if ("function" === typeof callback) {

                this.widget_init();
            }
        },
        widget_refresh: debounce(function () {

            return this.widget_refresh_promise();
        }, 500),
        widget_getCallback() {

            return window.__SupportWidgetListener;
        },
        widget_appendSelectionProduct(options, product) {

            const trigger = JSON.parse(options);

            trigger.selection.push({
                component: "productSingle",
                value: product._id
            });

            return JSON.stringify(trigger);
        },
        widget_appendSelectionProducts(options, products) {

            const trigger = JSON.parse(options);

            trigger.selection.push({
                component: "products",
                value: products.map(product => product._id)
            });

            return JSON.stringify(trigger);
        }
    }
}