<template>
    <page-message>
        <template #header>{{header}}</template>
        <template #html>
            <div v-html="message"></div>
        </template>
    </page-message>
</template>

<script>

    import mixin_view from "@/mixins/view";
    import mixin_widget from "@mixins_widget";
    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsErrors403",
        components: {
            PageMessage
        },
        mixins: [mixin_view, mixin_widget],
        data() {

            return {
                t_path: "views.errors.403",

                message: null,
                header: null
            }
        },
        computed: {
            error() {

                return this.$route.params.error;
            },
            subject() {

                const path = this.error ? `views.errors.403.error.${this.error}.subject` : `views.errors.403.subject`;

                return this.$te(path) ? this.$t(path) : "";
            },
            params() {

                return {
                    ...this.widget_optionsModels,
                    email: process.env.VUE_APP_SUPPORT_EMAIL,
                    mailto: `mailto:${process.env.VUE_APP_SUPPORT_EMAIL}`,
                    subject: this.subject
                }
            },
            header() {

                return this.t(this.error ? `error.${this.error}.header` : "header", this.params);
            },
            message() {

                return this.t(this.error ? `error.${this.error}.html` : "html", this.params);
            }
        },
        watch: {
            "$i18n.locale": {
                immediate: true,
                handler() {

                    this.view_setTitle(this.header);
                }
            }
        }
    }
</script>