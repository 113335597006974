export default {
    cost_center: {
        label: "Cost Center Code",
        placeholder: "Cost Center Code",
        errors: {
            regex: "Invalid cost center code"
        },
        decorators: {
            cost_center_details: {
                label: "Company Code / Center Name",
                message: "{company_code} / {cost_center_name}"
            }
        }
    },
    order_note: {
        label: "Note"
    }
}