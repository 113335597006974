<template>
    <div class="orders-history">
        <loading v-if="processing"/>
        <div v-else>
            <!-- Loading error -->
            <div v-if="error">
                <div class="title">{{t('default.title')}}</div>
                <div class="error">{{t('error')}}</div>
            </div>
            <!-- Orders -->
            <div v-else-if="split">
                <!-- No orders -->
                <div class="no-orders" v-if="0 === products.length">{{t('noOrders')}}</div>
                <!-- Pending products -->
                <div v-if="0 < pending.length">
                    <div class="title">{{t('split.pending')}}</div>

                    <orders-history-order-product v-for="pendingProduct in pending" class="orders-history-item"
                                                  :key="'pending_' + pendingProduct._id"
                                                  :created-on="pendingProduct.createdOn"
                                                  :product="pendingProduct"/>
                </div>
                <!-- Processed products -->
                <div v-if="0 < processed.length" :class="{'mt-3':0 < pending.length}">
                    <div class="title">{{t('split.processed')}}</div>

                    <orders-history-order-product v-for="processedProduct in processed" class="orders-history-item"
                                                  :key="'processed_' + processedProduct._id"
                                                  :created-on="processedProduct.createdOn"
                                                  :product="processedProduct"/>
                </div>
            </div>
            <div v-else>
                <div class="title">{{t('default.title')}}</div>
                <!-- No orders -->
                <div class="no-orders" v-if="0 === products.length">{{t('noOrders')}}</div>
                <!-- All orders -->
                <orders-history-order-product v-for="(product, index) in products" class="orders-history-item"
                                              :key="'all_' + product._id"
                                              :product="product"
                                              :created-on="product.createdOn"
                                              :expand="0==index && 1 == products.length"/>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex"

    import {ORDERS_LIMIT} from "@/constants";

    import Spinner from "@/components/Spinner";
    import Loading from "@/components/Loading";
    import OrdersHistoryOrderProduct from "@/components/orders/OrdersHistoryOrderProduct";

    const PROCESSED_ORDER_STATUSES = process.env.VUE_APP_COMPONENT_ORDERHISTORY_SPLIT_PROCESSED_STATUSES.split(",")

    export default {
        name: "OrdersHistory",
        components: {
            OrdersHistoryOrderProduct,
            Loading,
            Spinner
        },
        data() {

            return {
                t_path: "components.orders.history",
                processing: false,
                split: 1 == process.env.VUE_APP_COMPONENT_ORDERHISTORY_SPLIT_ENABLED,
                error: false
            }
        },
        computed: {
            ...mapGetters({
                orders: "user/orders/items"
            }),
            products() {

                return this.orders
                    .reduce((result, order) => result.concat(
                        order.products
                            .filter(product => "undefined" === typeof product.archived || false === product.archived)
                            .map(product => ({
                                ...product,
                                createdOn: order.createdOn
                            }))
                    ), [])
            },
            pending() {
                // Check for order product has pending status
                return this.products.filter(product => -1 === PROCESSED_ORDER_STATUSES.indexOf(product.status));
            },
            processed() {
                // Check for order product does not have pending status
                return this.products.filter(product => -1 !== PROCESSED_ORDER_STATUSES.indexOf(product.status));
            }
        }
    }
</script>