<template>
    <div class="products-page panel">
        <div class="row">

            <div class="col-12">
                {{brand}}
                <div class="h3">
                    <div class="float-right pl-4 pb-2">
                        <products-product-cart class="btn-cart" :value="value" :display-add-toast="true"/>
                    </div>
                    {{name}}
                </div>

                <div v-if="estimatedDeliveryDate" v-html="estimatedDeliveryDate" class="estimated-delivery-date"></div>

                <div v-if="note" class="note" v-html="note"></div>
            </div>

            <div v-if="0 < value.pictures.length" class="col-12 offset-md-3 col-md-6 py-3">
                <products-product-thumbnails size="large" :value="value.pictures"/>
            </div>

            <div v-if="description" class="col-12">
                <h4>{{t('description')}}</h4>

                <p v-html="description"></p>
            </div>

            <!-- Widget item photo -->
            <img v-if="0 < value.pictures.length" :src="value.pictures[0].medium" :alt="name"
                 class="d-none"
                 :data-widget-item="widgetItem"/>
        </div>
    </div>
</template>

<script>

    import mixinProducts from "@/mixins/products";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    import ProductsProductThumbnails from "./ProductsProductThumbnails";
    import ProductsProductCart from "@/components/products/ProductsProductCart";

    export default {
        name: "ProductsProductPage",
        components: {
            ProductsProductCart,
            ProductsProductThumbnails
        },
        mixins: [mixinProducts],
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.products.page"
            }
        },
        computed: {
            brand() {

                return getTranslationValue(this.value.brand, ["name"], this.$i18n);
            },
            name() {

                return this.products_translateProductName(this.value);
            },
            description() {

                return this.products_translateProductDescription(this.value);
            },
            note() {

                return this.products_translateProductNote(this.value);
            },
            widgetItem() {

                return JSON.stringify({
                    item: this.value.sku
                })
            },
            estimatedDeliveryDate() {

                return this.products_translateProductEstimatedShipDate(this.value);
            }
        }
    }
</script>