<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <loading v-if="products.loading" />
                <div v-else>
                    <div v-if="catalogMessage" v-html="catalogMessage"></div>

                    <products-grouping v-for="grouping in products.groupings" :grouping="grouping"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixinView from "@/mixins/view";
    import mixinConstraints from "@/mixins/constraints";
    import mixinWidget from "@mixins_widget";
    import {groupings} from "@/helpers/plp";

    import Loading from "@/components/Loading";
    import ProductsList from "@/components/products/ProductsList";
    import {retryRequest} from "@/helpers/request";
    import {CATALOG_STATUS_MESSAGE} from "@/constants";
    import ProductsGrouping from "@/components/products/ProductsGrouping";

    const GROUPING = process.env.VUE_APP_HOME_PAGE_GROUPING_TYPE;

    export default {
        name: "ViewsCatalog",
        mixins: [mixinView, mixinConstraints, mixinWidget],
        components: {
            ProductsGrouping,
            ProductsList,
            Loading
        },
        data() {

            return {
                t_path: "views.catalog",

                groupings,

                products: {
                    loading: false,
                    groupings: [],
                    config: {
                        default: {
                            max_items: null,
                            item_wrap: true
                        }
                        // TODO: get config from backend
                        /*grouping_id: {
                            max_items: 3
                        }*/
                    }
                },
                catalogMessageSource: null
            };
        },
        computed: {
            catalogMessage() {

                return this.catalogMessageSource
                    ? this.$t(`catalog.message.${this.catalogMessageSource.message}`, {
                        equipmentQuizLink: this.$router.resolve({name: "quiz", query: {reset: true}}).fullPath,
                        widgetOptions: this.widget_optionsJson,
                        supportEmail: this.catalogMessageSource.email
                    })
                    : null
            }
        },
        methods: {
            handleConfig(data) {

                this.products.config.default.max_items = data.config.max_items;
                this.products.config.default.item_wrap = data.config.item_wrap;
            },
            handleCatalog(result) {

                const tree = result.groupings;

                if (0 == tree.length) {

                    this.products.loading = false;

                    this.$activity.log("catalog_no_groupings", {constraints: this.$store.getters["user/constraints/type"]});
                    this.$activity.log("catalog_no_products", {constraints: this.$store.getters["user/constraints/type"]});

                    return false;
                }

                this.$activity.log("catalog_groupings", {
                    groupings: tree.map(grouping => ({name: grouping.name, id: grouping.id})),
                    constraints: this.$store.getters["user/constraints/type"]
                });

                const promises = tree.map(item => retryRequest(() => this.$platform.get(`/api/v2/grouping/${GROUPING}/${item.id}`)));

                return Promise.all(promises).then(responses => {

                    responses.forEach(response => {

                        const result = response.data.results;
                        const items = this.constraints_filterAvailableProducts(result.products);
                        const maxItem = this.products.config[result._id]
                            ? this.products.config[result._id].max_items
                            : this.products.config.default.max_items;
                        const total = "undefined" !== typeof result.total
                            ? result.total
                            : items.length;

                        this.$activity.log("catalog_grouping_products", {
                            grouping_id: result._id,
                            products: items.slice(0, maxItem),
                            constraints: this.$store.getters["user/constraints/type"]
                        });

                        if (0 < items.length) {

                            this.products.groupings.push({
                                ...result,
                                id: result._id,
                                type: GROUPING,
                                items: items.slice(0, maxItem),
                                showMore: total > items.slice(0, maxItem).length
                            });
                        }
                    });

                }).finally(() => this.products.loading = false);
            },
            fetch() {

                if (!this.constraints_hasUserAvailableProducts()) {

                    this.$activity.log("catalog_no_products", {constraints: this.$store.getters["user/constraints/type"]});

                    return false;
                }

                this.products.loading = true;

                const promises = [
                    retryRequest(() => this.$platform.get(`/api/v2/hp_config`)),
                    retryRequest(() => this.$platform.get(`/api/v2/groupingList/${GROUPING}`))
                ];

                if (CATALOG_STATUS_MESSAGE) {

                    promises.push(this.$store.dispatch("user/catalog/getCatalogMessage"))
                }

                return Promise.all([
                    retryRequest(() => this.$platform.get(`/api/v2/hp_config`)),
                    retryRequest(() => this.$platform.get(`/api/v2/groupingList/${GROUPING}`)),
                    this.$store.dispatch("user/catalog/getCatalogMessage")
                ]).then(responses => {

                    const [config, catalog, catalogMessage] = responses;

                    this.handleConfig(config.data.results);
                    this.handleCatalog(catalog.data.results);

                    this.catalogMessageSource = catalogMessage && catalogMessage.message
                        ? catalogMessage
                        : null;
                });
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>